import React from "react"
import { graphql } from "gatsby"

import { Maintenance as Page } from "../components/Maintenance/Maintenance"

export const query = graphql`
  query {
    page: sanitySettingMaintenance {
      title
      password
      enabled
      backgroundImage {
        asset {
          fluid(maxWidth: 2000) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`

export default ({ data, ...props }) => <Page {...props} {...data} />
