import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import Img from "gatsby-image"

import { withMaintenance } from "./withMaintenance"
import MaintenanceForm from "./MaintenanceForm"
import Icon from "../Icon"
import { StyledH1 } from "../Styled/Text"
import { GatsbyImageSourceProps } from "../../props/ImageProps"

const Container = tw.div`
  bg-light flex flex-row h-full-vh
`

const Image = styled(Img)`
  ${tw`w-1/2 hidden md:block`}
`

const ContentWrapper = tw.div`
  w-full md:w-1/2 flex items-center justify-center
`

const Content = tw.div`
  max-w-42 mx-2-4 w-full flex flex-col items-center
`

const Brand = styled(Icon)`
  ${tw`mb-2-4 md:mb-4`}
`
const Title = styled(StyledH1)`
  ${tw`mb-4-8 md:mb-6 text-center`}
`

export const Maintenance = withMaintenance(({ image, title, location }) => {
  return (
    <Container>
      <Image fluid={image} />
      <ContentWrapper>
        <Content>
          <Brand name={`logo`} size={`xxlarge`} />
          <Title>{title}</Title>
          <MaintenanceForm location={location} />
        </Content>
      </ContentWrapper>
    </Container>
  )
})

Maintenance.propTypes = {
  image: PropTypes.shape(GatsbyImageSourceProps),
  title: PropTypes.string,
}

export default Maintenance
