import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withMaintenanceForm } from "./withMaintenanceForm"
import FormInput from "../Form/FormInput"
import { StyledButton } from "../Styled/Button"

const Form = tw.form`
  w-full
`

const Input = styled(FormInput)`
  ${tw`px-0 mb-2-4`}
`

const Submit = styled(StyledButton)`
  ${tw`w-full mb-1-6`}
`

const Error = tw.p`text-12 text-orange`

export const MaintenanceForm = withMaintenanceForm(({ data, error, handleChange, handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <Input
      name={`password`}
      type={`password`}
      placeholder={`Enter storefront password`}
      placeholderColor={`dark`}
      handleChange={handleChange}
      value={data?.password}
    />
    <Submit height={`56`} textSize={`16`} colour={`light`} border onClick={handleSubmit}>
      Submit
    </Submit>
    {error && <Error>Invalid password</Error>}
  </Form>
))

export default MaintenanceForm
