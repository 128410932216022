import { useState, useCallback } from "react"
import { navigate } from "gatsby"

import { useCore } from "./useCore"
import { useApp } from "./useApp"
import { useSettings } from "./useSettings"

const INITIAL_STATE = {
  password: "",
}

export const useMaintenance = location => {
  const {
    helpers: { storage, decodeBase64, encodeBase64 },
  } = useCore()
  const {
    config: {
      settings: { routes, keys },
    },
  } = useApp()
  const {
    maintenance: { enabled, password },
  } = useSettings()
  const [error, setError] = useState(false)
  const [data, setData] = useState(INITIAL_STATE)

  const saved = storage.get(keys?.password)
  const authorised = saved && password === decodeBase64(saved)
  const active = location.pathname.startsWith(routes.PASSWORD)
  const params = new URLSearchParams(location?.search)

  if (typeof window !== `undefined`) {
    if (enabled) {
      storage.set(keys?.maintenance, enabled)
      if (!authorised && !active)
        navigate(`${routes.PASSWORD}${location.pathname !== routes.HOMEPAGE ? `?return=${location.pathname}` : ``}`, { replace: true })
    }

    if ((active && !enabled) || (active && enabled && authorised)) {
      storage.remove(keys?.maintenance)
      navigate(`${routes.HOMEPAGE}${params.get("return") || ``}`, { replace: true })
    }
  }

  const validatePassword = useCallback(() => {
    if (password === data?.password) {
      storage.set(keys?.password, encodeBase64(data?.password))
      setData(INITIAL_STATE)
      setError(false)
      navigate(`${routes.HOMEPAGE}${params.get("return") || ``}`, { replace: true })
    } else {
      storage.remove(keys?.password)
      setData(INITIAL_STATE)
      setError(true)
    }
  }, [encodeBase64, setData, setError, navigate, routes, params, password, data])

  return { active, error, data, setData, validatePassword }
}
