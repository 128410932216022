import React from "react"

import { useMaintenance } from "../../hooks/useMaintenance"

export const withMaintenanceForm = Component => ({ name = "MaintenanceForm", location }) => {
  const { validatePassword, data, setData, error } = useMaintenance(location)

  const handleSubmit = event => {
    event.preventDefault()
    validatePassword()
  }

  const handleChange = ({ target: { type, name, value, checked } }) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  Component.displayName = name
  return <Component data={data} error={error} handleChange={handleChange} handleSubmit={handleSubmit} />
}
